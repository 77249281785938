import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'

const ButtonWrapper = styled.button`
  padding: 0 14px;
  width: ${props => props.width || 'auto'};
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #e5195f;
  border: none;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  &:hover {
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.16);
    transform: translate(0, -5px);
  }
  &:focus {
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px ${props => (props.bgColor ? props.bgColor : '#dc3545')};
    outline: none;
  }
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${props => (props.disabled ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : 'none')};
  ${props =>
    props.disabled &&
    `
      background-color: #a0b7bf !important;
      border: none;
      color: #fff;
    `};
  background: ${props => props.bgColor || props.bgColor};
`

const spin = keyframes`
0% { transform: rotate(0deg) }
100% {transform: rotate(360deg) }
`
const Circle = styled.div`
  border: 3px solid ${props => (props.loadingBgColor ? props.loadingBgColor : '#fff')};
  border-top: 3px solid ${props => (props.loadingFgColor ? props.loadingFgColor : '#dc3545')};
  border-radius: 50%;
  margin: auto;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
`

class Button extends Component {
  render() {
    const { width, social, color, label, isLoading, bgColor, disabled, padding, fontSize, ...props } = this.props
    return (
      <ButtonWrapper
        bgColor={bgColor}
        width={width}
        disabled={disabled}
        padding={padding}
        fontSize={fontSize}
        {...props}>
        {social ? <React.Fragment>{label}</React.Fragment> : isLoading ? <Circle /> : label}
      </ButtonWrapper>
    )
  }
}

export default Button
