export default {
  primary: '#dc3545',
  secondary: '#212529',
  secondaryLight: 'rgba(33, 37, 41, 0.8)',
  blueBlack: '#0e1e24',
  white: '#fff',
  whitefa: '#fafafa',
  black: '#000',
  grey: '#ddd',
  skyblue: '#007bff',
  error: 'red'
}
