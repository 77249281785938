import React, { Component } from 'react'
import styled from 'styled-components'

const Wrapper = styled.textarea`
  resize: none;
  outline: none;
  border: 1px solid #ddd;
  margin-bottom: 12px;
  width: 100%;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 16px;
  letter-spacing: 0.8px;
  box-sizing: border-box;
  color: #666;
  &:focus {
    border-color: #dc354590;
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em #dc354590 !important;
  }

  &:disabled {
    background-color: #f4f8f9;
  }
  &:valid {
    & + div {
      opacity: 1;
    }
  }
  &:required {
    box-shadow: none;
  }
  &:not(output):-moz-ui-invalid {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  &:not(output):-moz-ui-invalid:-moz-focusring {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  /* Placeholder color */
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
`

class Textarea extends Component {
  state = {}
  render() {
    const { ...props } = this.props
    return <Wrapper {...props} />
  }
}

export default Textarea
