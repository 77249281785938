import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { StyledImage, FlexWrapper } from './StyledComponents'
import Icon from './Icon'
import IconMenu from './IconMenu'
import styled, { withTheme } from 'styled-components'
const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2%;
  height: 68px;
  box-shadow: 0px 2px 2px 0px #ddd;
  position: sticky;
  top: 0;
  z-index: 999;
  background: ${({ theme }) => theme.white};
`

class DashboardHeader extends Component {
  state = {}
  render() {
    return (
      <Wrapper>
        <Link to="/">
          <StyledImage src={require('../images/logo.png')} alt="Dopshub" title="Dopshub" height="40px" />
        </Link>
        <IconMenu icon={'facebook'}>
          <Link to="/">Account Settings</Link>
          <p onClick={() => {}}>Log Out</p>
        </IconMenu>
      </Wrapper>
    )
  }
}

export default withTheme(DashboardHeader)
