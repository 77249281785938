import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { withRouter, Redirect, Route, BrowserRouter, Switch } from 'react-router-dom'
import 'react-table/react-table.css'
import Cookies from 'js-cookie'
import './index.css'
import axios from 'axios'
import { has } from 'lodash'
import qs from 'qs'

// import AddDeploy from './components/addDeploy'
import { activePath } from './utils/functions'
import { Home, LoginAndSignup, Dashboard } from './pages'
import Deployments from './pages/deployments'
import MyContext from './context'
import { API_URL } from './constants'
import colors from './statics/colors'
import { DashboardHeader, Menu, MenuItem, StyledLink } from './components'
import Colors from './statics/colors'

const MenuWrapper = styled.div`
  width: 130px;
  z-index: 9;
  background: #fff;
  box-shadow: 2px 0 2px 0px #ddd;
`
const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 72px);
`

const Wrapper = styled.div`
  display: flex;
  min-width: 1024px;
  margin: auto;
`

const PrivateOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      // check params for access token for google and github signup
      const urlparams = qs.parse(props.location.search.slice(1))
      console.log('urlparams', urlparams)
      if (has(urlparams, 'access_token')) {
        Cookies.set('access_token', urlparams.access_token, { path: '/' })
        axios.defaults.headers['Authorization'] = `Token ${urlparams.access_token}`
        return <Redirect to={{ pathname: '/' }} />
      } else {
        const token = Cookies.get('access_token')
        axios.defaults.headers['Authorization'] = `Token ${Cookies.get('access_token')}`
        if (token) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      }
    }}
  />
)

const PublicOnlyRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => <Component {...props} />} />
}

class ScrollToTop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return this.props.children
  }
}
const RoutedScroll = withRouter(ScrollToTop)

class App extends Component {
  state = {
    appMetaData: []
  }
  fetchApplications = async () => {
    const res = await axios.get('deployments/applications/')
    this.setState({ appMetaData: res.data })
  }
  componentDidMount() {
    if (Cookies.get('access_token')) {
      this.fetchApplications()
    }
  }
  render() {
    const { appMetaData } = this.state
    axios.defaults.baseURL = API_URL
    return (
      <BrowserRouter>
        <RoutedScroll>
          <ThemeProvider theme={colors}>
            <MyContext.Provider value={{ appMetaData: appMetaData }}>
              <div className="App">
                <Switch>
                  <PublicOnlyRoute exact path="/" component={Home} />
                  <PublicOnlyRoute path="/login" component={LoginAndSignup} />
                  <PublicOnlyRoute path="/signup" component={LoginAndSignup} />
                  <Route
                    component={() => (
                      <React.Fragment>
                        <PrivateOnlyRoute component={DashboardHeader} />
                        <Wrapper>
                          <MenuWrapper>
                            <Menu>
                              <StyledLink color={Colors.primary} to="/dashboard">
                                <MenuItem active={activePath('overview')} iconName="facebook">
                                  Overview
                                </MenuItem>
                              </StyledLink>
                              <StyledLink color={Colors.primary} to="/deployments/add">
                                <MenuItem active={activePath('deployments')} iconName="facebook">
                                  Deployments
                                </MenuItem>
                              </StyledLink>
                              <StyledLink color={Colors.primary} to={'/user-integration'}>
                                <MenuItem active={activePath('integration')} iconName="facebook">
                                  Integration
                                </MenuItem>
                              </StyledLink>
                              <StyledLink color={Colors.primary} to={'/statistics'}>
                                <MenuItem active={activePath('statistics')} iconName="facebook">
                                  Statistics
                                </MenuItem>
                              </StyledLink>
                              <StyledLink color={Colors.primary} to={'/support'}>
                                <MenuItem active={activePath('support')} iconName="facebook">
                                  Support
                                </MenuItem>
                              </StyledLink>
                            </Menu>
                          </MenuWrapper>
                          <Container>
                            <Switch>
                              <PrivateOnlyRoute path="/dashboard" component={Dashboard} />
                              <PrivateOnlyRoute path="/deployments" component={Deployments} />
                              <PrivateOnlyRoute path="/user-integration" component={Deployments} />
                              <PrivateOnlyRoute path="/user-billing" component={Deployments} />
                              <PrivateOnlyRoute path="/profile" component={Deployments} />
                              <PrivateOnlyRoute path="/account-settings" component={Deployments} />
                              <PrivateOnlyRoute path="/statistics" component={Deployments} />
                              <PrivateOnlyRoute path="/support" component={Deployments} />
                              {/* <Route component={ErrorPage} /> */}
                            </Switch>
                          </Container>
                        </Wrapper>
                      </React.Fragment>
                    )}
                  />

                  {/* <PrivateOnlyRoute path="/add-deploy" component={AddDeploy} /> */}
                </Switch>
              </div>
            </MyContext.Provider>
          </ThemeProvider>
        </RoutedScroll>
      </BrowserRouter>
    )
  }
}

export default App
