import React, { Component } from 'react'
// import styled from 'styled-components';
import { DashboardHeader } from '../components'
import Sidebar from '../components/sidebar'

// const NavBar = styled.div`
// background:skyblue;
// `;

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Dashboard</h1>
        {/* <MainContents /> */}
      </React.Fragment>
    )
  }
}

export default Dashboard
