export const JSONToFormData = function(obj, form, namespace) {
  var fd = form || new FormData()
  var formKey

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        JSONToFormData(obj[property], fd, property)
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

export const activePath = currentPath => {
  if (currentPath === 'overview') {
    return window.location.pathname.split('/')[1] === 'dashboard'
  } else if (currentPath === 'deployments') {
    return window.location.pathname.split('/')[2] === 'add'
  } else if (currentPath === 'integration') {
    return window.location.pathname.split('/')[1] === 'user-integration'
  } else if (currentPath === 'statistics') {
    return window.location.pathname.split('/')[1] === 'statistics'
  } else if (currentPath === 'support') {
    return window.location.pathname.split('/')[1] === 'support'
  }
}
