import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import logo from '../images/logo.png'
import Button from './Button'

const Wrapper = styled.header`
  display: flex;
  height: 68px;
  /* box-shadow: 0px 2px 2px 0px #ddd; */
  position: sticky;
  top: 0;
  z-index: 999;
  background: ${({ theme }) => theme.secondary};
  > nav {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10%;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      height: 100%;
      > a {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > ul {
        padding: 0 0 0 40px;
        display: flex;
        height: 100%;
        align-items: center;
        margin-bottom: 0;
        > li {
          position: relative;
          display: flex;
          height: 100%;
          align-items: center;
          list-style: none;
          padding: 0 20px;
          :hover {
            ::before {
              content: '';
              background: rgba(0, 0, 0, 0.8);
              display: inline-block;
              position: absolute;
              left: 0%;
              padding: 1rem;
              right: 0%;
              z-index: -1;
              break-inside: avoid;
              transition: all 0.1s;
              transform: rotate(-1deg) skew(10deg);
            }
          }

          > a {
            display: flex;
            height: 100%;
            align-items: center;
            color: ${({ theme }) => theme.white};
            font-size: 18px;
            font-weight: 600;
            transition: 0.4s linear;
            :hover {
              text-decoration: none;
              /* color: ${({ theme }) => theme.secondary}; */
            }
          }
        }
      }
    }
  }
`

class Header extends React.Component {
  state = {
    isHovering: false
  }
  handleMouseHover = () => {
    this.setState({ isHovering: !this.state.isHovering })
  }
  render() {
    const { theme } = this.props
    const { isHovering } = this.state
    return (
      <React.Fragment>
        <Wrapper>
          <nav>
            <div>
              <Link to="/">
                <img src={logo} alt="Dopshub" title="Dopshub" height="40" />
              </Link>
              <ul>
                <li>
                  <Link title="Home" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link title="Our Story" to="/">
                    Our Story
                  </Link>
                </li>
                <li onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover}>
                  <Link title="Services" to="/">
                    Services
                  </Link>
                  {/*   {isHovering && (
                    <Card viewPadding="20px" style={{ position: 'absolute', top: '50px', zIndex: 999999 }}>
                      <p>Hey</p>
                    </Card>
                  )} */}
                </li>

                <li>
                  <Link title="What's new" to="/">
                    What's new
                  </Link>
                </li>
                {/* <li>
                <Link to="/">Help and Support</Link>
              </li> */}
              </ul>
            </div>
            <Button label="Login to console" onClick={() => this.props.history.push('/login')} />
          </nav>
        </Wrapper>
      </React.Fragment>
    )
  }
}

export default withTheme(withRouter(Header))
