import styled, { withTheme } from 'styled-components'
import { Link } from 'react-router-dom'
import Colors from '../statics/colors'

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  margin: ${props => (props.viewMargin ? props.viewMargin : '0px')};
  padding: ${props => (props.viewPadding ? props.viewPadding : '0px')};
  flex-basis: ${props => (props.flexBasis ? props.flexBasis : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  width: ${props => (props.width ? props.width : '100%')};
  min-width: ${props => (props.minWidth ? props.minWidth : 'auto')};
  border: ${props => (props.border ? props.border : 'none')};
  background: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : 'nowrap')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')};
  position: ${props => (props.position ? props.position : 'static')};
`

const StyledImage = styled.img`
  width: ${props => props.width || 'auto'};
  max-width: ${props => props.maxWidth || 'initial'};
  height: ${props => props.height || 'auto'};
  object-fit: ${props => props.objectFit || 'cover'};
`

const Section = styled.section`
  padding: 50px 10%;
`
const StyledH2 = styled.h2`
display:inline-flex;
position: relative;
align-self:center;
    color: ${({ theme }) => theme.white};
    text-align: center;

    ::before {
      content: '';
      box-shadow: 0px 4px 4px 0px #ddd;
      background: rgba(0, 0, 0, 0.8);
      display: inline-block;
      position: absolute;
      left: 0%;
      padding: 1.5rem;
      right: 0%;
      z-index: -1;
      break-inside: avoid;
      transition: all 0.1s;
      transform: rotate(-1deg) skew(10deg);
    }
  }
  :hover {
    ::before {
      transform: rotate(-1deg) skew(-5deg);
    }
`
const StyledText = styled.p`
  width: ${props => props.width || 'auto'};
  font-size: ${props => props.fontSize || '16px'};
  color: ${props => props.color || '#000'};
  line-height: normal;
  font-weight: ${props => props.fontWeight || 'normal'};
  background-color: ${props => props.background || 'transparent'};
  text-align: ${props => props.textAlign || 'left'};
  letter-spacing: ${props => props.letterSpacing || '0.6px'};
  padding: ${props => props.viewPadding || '0px'};
  text-decoration-line: ${props => props.textDecorationLine || 'none'};
`
const StyledLink = styled(Link)`
  padding: ${props => props.viewPadding || '0'};
  color: ${props => props.color || Colors.grey};
  transition: 0.2s linear;
  font-weight: 500;
  :hover {
    color: ${props => props.hoverColor || Colors.primary};
    text-decoration: none;
  }
`
export { Section, StyledH2, StyledText, FlexWrapper, StyledImage, StyledLink }
