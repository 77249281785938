import React from 'react'
import styled, { withTheme } from 'styled-components'
import Colors from '../statics/colors'

const Wrapper = styled.div`
  width: ${props => props.width || 'auto'};
  max-width: ${props => props.maxWidth && props.maxWidth};
  height: ${props => props.height || 'auto'};
  background: ${props => props.background || Colors.white};
  box-shadow: 0 2px 4px 0 rgba(14, 30, 37, 0.12);
  border-radius: ${props => props.borderRadius || '8px'};
  padding: ${props => props.viewPadding || '10px'};
  margin: ${props => props.viewMargin || '0px'};
`
const Card = props => <Wrapper {...props} />
export default withTheme(Card)
