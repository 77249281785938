import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Header, Footer, Button, Section, StyledH2, Input, Textarea } from '../components'
import Particles from 'react-particles-js'

const Position = styled.div`
  position: absolute;
  top: 68px;
  left: auto;
  right: auto;
  padding: 5% 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 400px;

  > h1 {
    font-size: 64px;
    color: ${({ theme }) => theme.white};
    font-weight: 600;
  }
  > p {
    font-size: 18px;
    color: ${({ theme }) => theme.whitefa};
    font-weight: 500;
  }
  > button {
    align-self: center;
  }
`

const Wrap = styled.div`
  height: 400px;
  > div {
    position: relative;
  }
`
const SectionWrapper = styled.div`
  padding-top: 30px;
  & > p {
    font-size: 18px;
    font-weight: 600;
  }
`
const Form = styled.form`
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
`
class Home extends React.Component {
  render() {
    const { theme } = this.props
    return (
      <React.Fragment>
        <Header />
        <Wrap>
          <Particles height={400} style={{ background: theme.secondary, height: '400px' }} />
        </Wrap>
        <Position>
          <h1>To Get Deployed</h1>
          <p>Run your crawlers smoothly and prevent them to get blocked.</p>
          <Button label="Read more" />
        </Position>
        <Section>
          <SectionWrap>
            <StyledH2>Deploy Scrapy</StyledH2>
            <SectionWrapper>
              <p>
                Running Scrapy instances over cloud seems a mess when it comes to managing them to run periodically and
                monitor actively. If somehow anyone manages then infrastructure requires an immense cost that does not
                suit to business model.
              </p>
              <p>
                What if you invest few minutes to deploy your scrapy project on Dopshub and run concurrent crawl
                processes and get DEBUG level log monitoring, downloading of item feeds, and much more with attractive
                pricing.
              </p>
              <p>
                We especially worked to reduce the cost and offer you all possible features via user-friendly UI and Web
                API. If there is something that we missed and you want that included on priority or you have a different
                use case, then post us by clicking below button.
              </p>
            </SectionWrapper>
            {/* <div>
                <h2>Deploy Scrapy</h2>
                <p />
                <p>
                  Running Scrapy instances over cloud seems a mess when it comes to managing them to run periodically
                  and monitor actively. If somehow anyone manages then infrastructure requires an immense cost that does
                  not suit to business model.
                </p>
                <p>
                  What if you invest few minutes to deploy your scrapy project on Dopshub and run concurrent crawl
                  processes and get DEBUG level log monitoring, downloading of item feeds, and much more with attractive
                  pricing.
                </p>
                <p>
                  We especially worked to reduce the cost and offer you all possible features via user-friendly UI and
                  Web API. If there is something that we missed and you want that included on priority or you have a
                  different use case, then post us by clicking below button.
                </p>
              </div> */}
          </SectionWrap>
        </Section>
        <Section>
          <SectionWrap>
            <StyledH2>See What's Next</StyledH2>
            <Form action="https://formspree.io/samarpit@dopshub.com" method="POST">
              <Input type="email" placeholder="Your email" name="_replyto" required />
              <Textarea name="message" placeholder="Tell us about your project." />
              <Button type="submit" label="Send" />
            </Form>
          </SectionWrap>
        </Section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default withTheme(Home)
