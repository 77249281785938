import React, { Component } from 'react'
import { StyledText } from './StyledComponents'
import { withTheme } from 'styled-components'
class ResponseMessage extends Component {
  render() {
    const { theme, message } = this.props
    return (
      <React.Fragment>
        <StyledText color={theme.primary} fontSize="16px" viewPadding="4px 0 0 0">
          {message}
        </StyledText>
      </React.Fragment>
    )
  }
}
export default withTheme(ResponseMessage)
