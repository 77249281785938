import React from 'react'
import styled, { withTheme } from 'styled-components'
import Icon from './Icon'

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
`
const ItemName = styled.p`
  color: ${({ theme }) => theme.secondaryLight};
  font-size: 15px;
  margin: 0px;
  padding: 16px 0px 6px 0px;
  line-height: 20px;
  position: relative;
  /* ::before {
      content: '';
      color: ${({ theme }) => theme.primary};
      box-shadow: 0px 4px 4px 0px #ddd;
      background: rgba(0, 0, 0, 0.8);
      display: inline-block;
      position: absolute;
      left: 0%;
      padding: 10px;
      right: 0%;
      z-index: -1;
      break-inside: avoid;
      transition: all 0.1s;
      transform: rotate(-1deg) skew(10deg);
    }
  }
  :hover {
    ::before {
      transform: rotate(-1deg) skew(-5deg);
    }
  } */
`

const ItemWrapper = styled.div`
  width: 130px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  &:hover {
    /* background: rgba(220, 53, 69, 0.1); */
    > div > svg path {
      fill: ${props => props.theme.primary};
    }
    > p {
      color: ${props => props.theme.primary};
    }
    ::before {
      content: '';
      width: 10px;
      height: 100%;
      position: absolute;
      box-shadow: ${props => (props.active ? ' 2px 1px 11px 0px inherit' : 'none')} !important;
      left: 0px;
      background-color: ${props => props.theme.primary};
      border-radius: 0 8px 8px 0;
      z-index: 9;
      ${props =>
        props.theme.verticalAlign &&
        `
        top: 0px;
        left: unset;
        width: 100%;
        height: 10px;
        border-radius: 0px 0px 8px 8px;
        `};
    }
    & > div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(233, 239, 244, 0.4);
    }
  }
  ${props =>
    props.active &&
    `
    > div > svg path {
      fill: #dc3545;
    }
    > p {
      color: #dc3545;
    }

    & > div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: rgba(233, 239, 244, 0.4);
      &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba(233, 239, 244, 0.4); 
      }
    }
  &:after {
    content: '';
    width: 10px;
    height: 100%;
    position: absolute;
    left: 0px;
    border-radius: 0 8px 8px 0;
    box-shadow: 2px 1px 11px 0px rgba(1,172,253,1);
    z-index: 9;
  }
  `};
  ${props =>
    props.theme &&
    `
   
    &:after {
      background-color:${props.theme.primary} ;
    box-shadow: 2px 1px 11px 0px ${props.theme.primary} !important;
  `};
  ${props =>
    props.theme.verticalAlign &&
    `
    top: 0px;
    left: unset;
    width: 100%;
    height: 10px;
    border-radius: 0px 0px 8px 8px;
    `};
`

const MenuItem = ({ iconName, theme, children, ...props }) => (
  <ItemWrapper {...props}>
    <IconWrapper>
      <Icon icon={iconName ? iconName : 'facebook'} color={theme.secondaryLight} />
    </IconWrapper>
    <ItemName theme={theme}>{children}</ItemName>
  </ItemWrapper>
)

export default withTheme(MenuItem)
