import React from 'react'
import styled, { withTheme } from 'styled-components'
import { StyledText } from './StyledComponents'
import Icon from './Icon'

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
`

const StyledInput = styled.input`
  outline: none;
  border: 1px solid #ddd;
  height: 36px;
  width: 100%;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 16px;
  letter-spacing: 0.8px;
  box-sizing: border-box;
  color: #666;
  &:focus {
    border-color: #dc354590;
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em #dc354590 !important;
  }
  &:disabled {
    background-color: #f4f8f9;
  }
  &:valid {
    & + div {
      opacity: 1;
    }
  }
  &:required {
    box-shadow: none;
  }
  &:not(output):-moz-ui-invalid {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  &:not(output):-moz-ui-invalid:-moz-focusring {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  ${props =>
    props.readOnly &&
    `
  background-color: #f4f8f9;
  
  `};
  ${props =>
    props.passwordToggle &&
    `
  padding-right:35px;
  
  `};
  /* Placeholder color */
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: ${props => (props.placeholderColor ? props.placeholderColor : '#9b9b9b')};
  }
`

const Label = styled.p`
  color: ${props => (props.disabled ? '#9b9b9b' : '#323c47')};
  font-size: 15px;
  margin-bottom: 8px;
  letter-spacing: 0.8px;
  font-family: 'Lato', sans-serif;
  padding-bottom: 4px;
`
const Icons = styled.div`
  position: absolute;
  right: 10px;
  bottom: 12px;
  cursor: pointer;
  opacity: 0.4;
  display: flex;
  align-items: center;
`

class Input extends React.Component {
  state = { showPassword: false }
  render() {
    const {
      error,
      labelStyle,
      placeholderColor,
      disabled,
      label,
      passwordToggle = true,
      type,
      showTooltip,
      infoMessage,
      ...props
    } = this.props
    const { showPassword } = this.state
    return (
      <InputWrapper>
        {label && (
          <Label disabled={disabled} style={labelStyle && labelStyle}>
            {label}
          </Label>
        )}
        <StyledInput
          disabled={disabled}
          passwordToggle={passwordToggle}
          showTooltip={showTooltip}
          placeholderColor={placeholderColor && placeholderColor}
          type={type ? (showPassword ? 'text' : type) : 'text'}
          required
          {...props}
        />
        {type === 'password' && passwordToggle ? (
          <Icons onClick={() => this.setState({ showPassword: !showPassword })}>
            <Icon icon={showPassword ? 'passwordHide' : 'passwordShow'} />
          </Icons>
        ) : (
          ''
        )}
        {error ? (
          <StyledText fontSize="13px" color="red" style={{ position: 'absolute', bottom: -16, left: 4 }}>
            {error}
          </StyledText>
        ) : null}
      </InputWrapper>
    )
  }
}

export default withTheme(Input)
