import React from 'react'
import styled, { withTheme, createGlobalStyle } from 'styled-components'
import { Card, StyledImage, StyledText, FlexWrapper, Input, Button, StyledLink, ResponseMessage } from '../components'
import { Formik, Form, Field } from 'formik'
import { loginValidator, registerValidator } from '../utils/validators'
import { JSONToFormData } from '../utils/functions'
import Cookies from 'js-cookie'
import axios from 'axios'
import Particles from 'react-particles-js'

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.blueBlack} !important;
  }
`

const PageWrap = styled.div`
  > div:first-child {
    position: relative;
    height: 100vh;
  }
`
const SectionWrap = styled.section`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 10%;
`
class LoginAndSignup extends React.Component {
  state = {
    successMessage: '',
    isLoading: false,
    errorMessage: ''
  }
  render() {
    const { errorMessage, isLoading, successMessage } = this.state
    const {
      theme,
      location: { pathname }
    } = this.props
    return (
      <>
        <GlobalStyle />
        <PageWrap>
          <Particles
            params={{
              particles: {
                line_linked: {
                  shadow: {
                    enable: true,
                    color: '#3CA9D1',
                    blur: 5
                  }
                }
              }
            }}
            style={{ background: theme.blueBlack }}
          />
          <SectionWrap>
            <FlexWrapper alignItems="center">
              <StyledImage src={require('../images/logo.png')} height="50px" />
              <StyledText fontSize="40px" fontWeight={600} color={theme.white} viewPadding="20px 0 0 0">
                Welcome to Dopshub
              </StyledText>
            </FlexWrapper>
            <Card viewPadding="40px" viewMargin="30px auto" width="500px">
              {pathname === '/login' && (
                <React.Fragment>
                  <StyledText
                    color={theme.secondary}
                    fontSize="20px"
                    fontWeight={600}
                    textAlign="center"
                    viewPadding="0 0 20px">
                    Login to continue
                  </StyledText>
                  <Formik
                    enableReinitialize
                    initialValues={{ email: '', password: '' }}
                    validate={loginValidator}
                    validateOnChange
                    onSubmit={async values => {
                      this.setState({ isLoading: true, errorMessage: '' })
                      try {
                        const { data } = await axios.post(
                          '/users/login/',
                          JSONToFormData({
                            email: values.email,
                            password: values.password
                          })
                        )
                        Cookies.set('access_token', data.access_token)
                        if (data.account_type !== 'confirmed') {
                          this.setState({ errorMessage: 'Please verify your email address first' })
                        } else if (Cookies.get('access_token')) {
                          this.props.history.push('/dashboard')
                        }
                        this.setState({ isLoading: false })

                        console.log('data', data)
                      } catch (error) {
                        console.log('error', error.response)
                        this.setState({ isLoading: false, errorMessage: error.response.data.error })
                      }
                    }}
                    render={formikBag => {
                      return (
                        <Form>
                          <Field
                            name="email"
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="email"
                                name="email"
                                label="Email"
                                required
                                value={formikBag.values.email}
                                error={
                                  formikBag.touched.email && formikBag.errors.email ? formikBag.errors.email : null
                                }
                              />
                            )}
                          />
                          <Field
                            name="password"
                            render={({ field }) => (
                              <Input
                                {...field}
                                type="password"
                                name="password"
                                label="Password"
                                value={formikBag.values.password}
                                required
                                error={
                                  formikBag.touched.password && formikBag.errors.password
                                    ? formikBag.errors.password
                                    : null
                                }
                              />
                            )}
                          />
                          <FlexWrapper viewPadding="10px 0 0 0" alignItems="center">
                            <Button
                              width="100%"
                              label="Submit"
                              isLoading={isLoading}
                              disabled={isLoading}
                              type="submit">
                              Submit
                            </Button>
                          </FlexWrapper>
                        </Form>
                      )
                    }}
                  />
                  {errorMessage !== '' && <ResponseMessage message={errorMessage} />}
                  <FlexWrapper
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    viewPadding="14px 0 0 0">
                    <StyledLink color={theme.secondary} to="/forgotpassword">
                      Forgot Password?
                    </StyledLink>
                    <StyledLink color={theme.secondary} to="/signup">
                      Need an Account?
                    </StyledLink>
                  </FlexWrapper>
                </React.Fragment>
              )}
              {pathname === '/signup' && (
                <React.Fragment>
                  <StyledText
                    color={theme.secondary}
                    fontSize="20px"
                    fontWeight={600}
                    textAlign="center"
                    viewPadding="0 0 20px">
                    Signup to create a new account
                  </StyledText>
                  <Formik
                    enableReinitialize
                    initialValues={{ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' }}
                    validateOnChange
                    validate={registerValidator}
                    onSubmit={async values => {
                      this.setState({ errorMessage: '', isLoading: true })
                      try {
                        const { data } = await axios.post(
                          '/users/register/',
                          JSONToFormData({
                            email: values.email,
                            password: values.password,
                            first_name: values.firstName,
                            last_name: values.lastName
                          })
                        )
                        this.setState({ isLoading: false, successMessage: data.success })
                        console.log('data', data)
                      } catch (error) {
                        this.setState({ isLoading: false, errorMessage: error.response.data.error })
                        console.log('error', { error })
                      }
                    }}
                    render={formikBag => {
                      return (
                        <Form>
                          <Field
                            name="firstName"
                            render={({ field }) => (
                              <Input
                                {...field}
                                label="First Name"
                                type="text"
                                name="firstName"
                                value={formikBag.values.firstName}
                                error={
                                  formikBag.touched.firstName && formikBag.errors.firstName
                                    ? formikBag.errors.firstName
                                    : null
                                }
                              />
                            )}
                          />
                          <Field
                            name="lastName"
                            render={({ field }) => (
                              <Input
                                {...field}
                                label="Last Name"
                                type="text"
                                name="lastName"
                                value={formikBag.values.lastName}
                                error={
                                  formikBag.touched.lastName && formikBag.errors.lastName
                                    ? formikBag.errors.lastName
                                    : null
                                }
                              />
                            )}
                          />
                          <Field
                            name="email"
                            render={({ field }) => (
                              <Input
                                {...field}
                                label="Email"
                                type="email"
                                name="email"
                                value={formikBag.values.email}
                                error={
                                  formikBag.touched.email && formikBag.errors.email ? formikBag.errors.email : null
                                }
                              />
                            )}
                          />
                          <Field
                            name="password"
                            render={({ field }) => (
                              <Input
                                {...field}
                                label="Password"
                                type="password"
                                name="password"
                                value={formikBag.values.password}
                                error={
                                  formikBag.touched.password && formikBag.errors.password
                                    ? formikBag.errors.password
                                    : null
                                }
                              />
                            )}
                          />
                          <Field
                            name="confirmPassword"
                            render={({ field }) => (
                              <Input
                                {...field}
                                label="Confirm Password"
                                type="password"
                                name="confirmPassword"
                                value={formikBag.values.confirmPassword}
                                error={
                                  formikBag.touched.confirmPassword && formikBag.errors.confirmPassword
                                    ? formikBag.errors.confirmPassword
                                    : null
                                }
                              />
                            )}
                          />
                          <FlexWrapper viewPadding="10px 0 0 0" alignItems="center">
                            <Button
                              width="100%"
                              label="Signup"
                              isLoading={isLoading}
                              disabled={isLoading}
                              type="submit">
                              Submit
                            </Button>
                          </FlexWrapper>
                        </Form>
                      )
                    }}
                  />
                  {errorMessage !== '' && <ResponseMessage message={errorMessage} />}
                  {successMessage !== '' && (
                    <React.Fragment>
                      <ResponseMessage message={successMessage} />
                      <StyledLink to="/login">Go to login</StyledLink>
                    </React.Fragment>
                  )}

                  <FlexWrapper direction="row" alignItems="center" justifyContent="center" viewPadding="14px 0 0 0">
                    <StyledLink color={theme.secondary} to="/login">
                      Already have an account?
                    </StyledLink>
                  </FlexWrapper>
                </React.Fragment>
              )}
            </Card>
          </SectionWrap>
        </PageWrap>
      </>
    )
  }
}

export default withTheme(LoginAndSignup)
