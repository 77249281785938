import React from 'react'
import { FlexWrapper, StyledText, StyledLink } from './StyledComponents'
import styled, { withTheme } from 'styled-components'
import { Icon } from '.'

const FooterWrap = styled.footer`
  padding: 50px 10%;
  background: ${({ theme }) => theme.secondary};
`

const Flex = styled.div`
  > a {
    padding: 0 10px;
    transition: 0.4s linear;
  }
  > a:hover {
    > svg path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`
class Footer extends React.Component {
  render() {
    const { theme } = this.props
    const date_obj = new Date()
    const year = date_obj.getFullYear()
    return (
      <FooterWrap>
        <StyledText color={theme.white} fontSize="24px" fontWeight="700">
          Dopshub Logo
        </StyledText>
        {/* <address>
          <a href="/#">Comming soon</a>
        </address> */}
        {/* <h5>Follow Us</h5>
          <a href="/">Facebook</a>
          <a href="/">Twitter</a>
          <a href="/">Github</a>
          <a href="/">Instagram</a>
          <a href="/">Linkendin</a> */}
        <FlexWrapper direction="row" viewPadding="40px 0 0 0">
          <FlexWrapper>
            <StyledText color={theme.white} fontSize="20px" fontWeight={700} viewPadding="0 0 14px">
              What
            </StyledText>
            <FlexWrapper>
              <StyledLink viewPadding="0 0 10px" to="/">
                About us
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Clients
              </StyledLink>
            </FlexWrapper>
            <FlexWrapper />
          </FlexWrapper>
          <FlexWrapper>
            <StyledText color={theme.white} fontSize="20px" fontWeight={700} viewPadding="0 0 14px">
              Services
            </StyledText>
            <FlexWrapper>
              <StyledLink viewPadding="0 0 10px" to="/">
                Automation Tools
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Others
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Solutions on demand
              </StyledLink>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <StyledText color={theme.white} fontSize="20px" fontWeight={700} viewPadding="0 0 14px">
              Developers
            </StyledText>
            <FlexWrapper>
              <StyledLink viewPadding="0 0 10px" to="/">
                Scrapy Deployment
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Periodic Tasks
              </StyledLink>
            </FlexWrapper>
          </FlexWrapper>
          <FlexWrapper>
            <StyledText color={theme.white} fontSize="20px" fontWeight={700} viewPadding="0 0 14px">
              Resources
            </StyledText>
            <FlexWrapper>
              <StyledLink viewPadding="0 0 10px" to="/">
                Blog
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Support
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Terms of Service
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Abuse Report
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Privacy Policy
              </StyledLink>
              <StyledLink viewPadding="0 0 10px" to="/">
                Cookie Policy
              </StyledLink>
            </FlexWrapper>
          </FlexWrapper>
        </FlexWrapper>
        <FlexWrapper viewPadding="30px 0 0 0">
          <FlexWrapper direction="row">
            <Flex>
              <a href="/">
                <Icon icon="facebook" />
              </a>
              <a href="/">
                <Icon icon="twitter" />
              </a>
              <a href="/">
                <Icon icon="github" />
              </a>
              <a href="/">
                <Icon icon="instagram" />
              </a>
              <a href="/">
                <Icon icon="linkedin" />
              </a>
            </Flex>
          </FlexWrapper>
          <FlexWrapper viewPadding="20px 0 0 0">
            <StyledText color={theme.white}>© {year} Dopshub</StyledText>
          </FlexWrapper>
        </FlexWrapper>
      </FooterWrap>
    )
  }
}

export default withTheme(Footer)
