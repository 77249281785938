import React, { Component } from 'react'
// import Console from '../containers/console'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'
import axios from 'axios'
import moment from 'moment'
import MyContext from '../context'

class Deployments extends Component {
  state = {
    appMetaData: [],
    tableData: []
  }

  fetchDeployments = async () => {
    const res = await axios.get('deployments/')
    this.setState({ tableData: res.data })
  }

  fetchApplications = async () => {
    const res = await axios.get('deployments/applications/')
    this.setState({ appMetaData: res.data })
    console.log('hdvcdsvcjsdbf', this.state)
  }
  componentDidMount() {
    this.fetchDeployments()
    this.fetchApplications()
  }
  render() {
    const { tableData } = this.state
    return (
      <>
        {/* <Console /> */}
        <MyContext.Consumer>
          {context => {
            console.log(context)
            return (
              <div className="Deployment_table main-contents">
                <div className="main">
                  <nav>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Deployments</li>
                    </ol>
                  </nav>
                  <div className="introduction">
                    <Link to={{ pathname: '/add-deploy', appMetaData: this.state.appMetaData }}>
                      <button className="btn btn-primary float-right">
                        <i className="fa fa-plus" />
                      </button>
                    </Link>
                    <h5>Deployment Table</h5>
                  </div>
                  <ReactTable
                    data={tableData}
                    minRows={3}
                    sorted={[
                      {
                        id: 'createt_at',
                        desc: true
                      }
                    ]}
                    columns={[
                      {
                        columns: [
                          {
                            Header: 'Name',
                            accessor: 'name'
                          },
                          {
                            Header: 'Application',
                            accessor: 'application_id',
                            Cell: row => row.original.application_id
                          },
                          {
                            Header: 'Status',
                            accessor: 'status',
                            Cell: row => String(row.original.status)
                          },
                          {
                            Header: 'Created',
                            accessor: 'created_at',
                            Cell: row => moment(row.original.created_at).format('DD MMM YYYY hh:mm:ss a')
                          }
                        ]
                      }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                  />
                </div>
              </div>
            )
          }}
        </MyContext.Consumer>
      </>
    )
  }
}

export default Deployments
