export const loginValidator = values => {
  let errors = {}
  if (!values.email) {
    errors.email = 'Please enter your email address'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Field is required'
  }
  return errors
}
export const registerValidator = values => {
  let errors = {}
  if (!values.firstName) {
    errors.firstName = 'Field is required'
  }
  if (!values.lastName) {
    errors.lastName = 'Field is required'
  }
  if (!values.email) {
    errors.email = 'Please enter your email address'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Field is required'
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Field is required'
  } else if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Passwords doesn\'t match'
  }
  return errors
}
